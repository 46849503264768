import intlTelInput, { Iti } from 'intl-tel-input';

import retry from '@rover/rsdk/src/modules/Network/retry';
import { ACTIVE_COUNTRY_CODES } from '@rover/shared/js/constants/roverGeo.constants';

import 'intl-tel-input/build/css/intlTelInput.css';
// this file contains a wrapper over Google phone lib data and is lazily loaded by intl-tel-input
export const UTIL_URL =
  'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/23.1.1/js/utils.min.js';

/**
 * Most of this file was adapted from /frontend/app/js/components/intl_tel_input.js
 */
const DEFAULT_LOCALE = 'en-us';
const MISSING_MODULE_MESSAGE = 'Cannot find module';

export const LOWER_CASE_COUNTRY_CODES = ACTIVE_COUNTRY_CODES.map((cc) => cc.toLowerCase());

const handleChange = (intlTelInputInstance: Iti, onChange) => {
  onChange(intlTelInputInstance.getNumber(), intlTelInputInstance.isValidNumber());
};

export function getPhoneInstance(el: HTMLInputElement) {
  // getInstance is only available in globals
  return intlTelInput.getInstance(el);
}

const getLocalizedCountries = async (locale: string) => {
  if (locale === DEFAULT_LOCALE) {
    return {};
  }

  return retry(async () => {
    try {
      // jest tries (and fails) to resolve this when it's inlined in the `import(...)` call
      const localeModulePath = `@rover/app/locale/${locale}/countries.json`;
      const localeModule = await import(`${localeModulePath}`); // the extra interpolation here fixes a webpack warning: "Critical dependency: the request of a dependency is an expression"
      return localeModule;
    } catch (error: any) {
      // We expect this to fail if locale doesn't have countries defined
      // and we fall back to the US list. In all other cases, we should retry
      if (!error.message.includes(MISSING_MODULE_MESSAGE)) {
        throw error;
      }
    }

    return {};
  });
};

type CleanupFunction = () => void;
type PhonePluginRef = {
  cleanup: CleanupFunction;
  pluginInstance: Iti;
};
type InitIntlPhoneInputOptions = {
  onChange?: (arg0: string, arg1: boolean) => void;
  countryCode?: string;
  locale?: string;
  preferredCountries?: string[];
  useHiddenInput?: boolean;
};
type EventHandler = {
  event: string;
  handler: (e: Event) => void;
};

function getTextFromPasteEvent(e: ClipboardEvent) {
  // @ts-expect-error
  if (window.clipboardData && window.clipboardData.getData) {
    // IE
    // @ts-expect-error
    return window.clipboardData.getData('Text');
  }

  if (e.clipboardData && e.clipboardData.getData) {
    return e.clipboardData.getData('text/plain');
  }

  return '';
}

function getFormatedPhoneNumber(rawPhoneNumber: string, intlTelInputInstance: Iti): string {
  if (!intlTelInput.utils) return rawPhoneNumber;
  return intlTelInput.utils.formatNumber(
    rawPhoneNumber,
    intlTelInputInstance.getSelectedCountryData().iso2,
    intlTelInput.utils.numberFormat.INTERNATIONAL
  );
}

function registerEvents(el: HTMLElement, eventHandlers: EventHandler[] = []): () => void {
  eventHandlers.forEach(({ event, handler }) => {
    el.addEventListener(event, handler);
  });
  return () => {
    eventHandlers.forEach(({ event, handler }) => {
      el.removeEventListener(event, handler);
    });
  };
}

export const initIntlPhoneInput = async (
  inputRef: HTMLInputElement,
  {
    onChange = () => {},
    countryCode = 'us',
    locale = DEFAULT_LOCALE,
    preferredCountries = LOWER_CASE_COUNTRY_CODES,
    // if specified, will use hidden input to store the real value
    // this is needed when form is submitted with html form and not through ajax
    useHiddenInput = false,
  }: InitIntlPhoneInputOptions
): Promise<PhonePluginRef> => {
  const localizedCountries = await getLocalizedCountries(locale);

  // extra insurance that we don't use an empty list of preferredCountries
  if (preferredCountries.length === 0) {
    preferredCountries = LOWER_CASE_COUNTRY_CODES; // eslint-disable-line no-param-reassign
  }

  /* eslint-disable no-param-reassign */
  let hiddenInputName = '';

  if (useHiddenInput) {
    hiddenInputName = inputRef.name;
    inputRef.name = '';
  }

  /* eslint-enable no-param-reassign */
  const intlTelInputInstance: Iti = intlTelInput(inputRef, {
    // don't supply utilsScript during jest runs, it causes async things to fail and break tests
    utilsScript: process.env.JEST_WORKER_ID ? undefined : UTIL_URL,
    autoPlaceholder: 'off',
    onlyCountries: preferredCountries,
    i18n: localizedCountries,
    initialCountry: countryCode,
    hiddenInput: () => ({ phone: hiddenInputName }),
  });

  let isHandlingChange = false;

  const eventHandlers = [
    {
      event: 'countrychange',
      handler: () => {
        if (isHandlingChange) return;
        isHandlingChange = true;
        const currentText = inputRef.value;
        const formatedPhoneNumber = getFormatedPhoneNumber(currentText, intlTelInputInstance);

        if (formatedPhoneNumber !== intlTelInputInstance.getNumber()) {
          intlTelInputInstance.setNumber(formatedPhoneNumber);
        }

        handleChange(intlTelInputInstance, onChange);
        isHandlingChange = false;
      },
    },
    {
      event: 'paste',
      handler: (e) => {
        if (isHandlingChange) return;
        isHandlingChange = true;
        // this handles the case where user pastes a full phone number to the field which will triggers max-length 12 error
        e.preventDefault();
        e.stopPropagation();
        const currentText = getTextFromPasteEvent(e);
        const formatedPhoneNumber = getFormatedPhoneNumber(currentText, intlTelInputInstance);
        intlTelInputInstance.setNumber(formatedPhoneNumber);
        handleChange(intlTelInputInstance, onChange);
        isHandlingChange = false;
      },
    },
    {
      event: 'input',
      handler: () => {
        if (isHandlingChange) return;
        isHandlingChange = true;
        handleChange(intlTelInputInstance, onChange);
        isHandlingChange = false;
      },
    },
  ];
  const cleanupEvents = registerEvents(inputRef, eventHandlers);
  await intlTelInputInstance.promise;
  return {
    cleanup: () => {
      intlTelInputInstance.destroy();
      cleanupEvents();
    },
    pluginInstance: intlTelInputInstance,
  };
};
export default initIntlPhoneInput;
